/* content container 
--------------------- */
#content-wrapper {
    display: block;
    margin: 0 auto;
    max-width: $breakpoint-large;
    min-height: 70vh;
    text-align: center;
    padding: 80px 0;
    width: $content-width;
    
    @include breakpoint(medium) {
        padding: 80px 0 20px 0;
    }
}
		
/* content main 
--------------------- */
#content-main {
    display: inline-block;
    text-align: left;
    width: $full-width; 
    
    .main-left {
        @include alpha-attribute("background-color", rgba($white, 0.8), $white);
        padding-bottom: $column-space - 10;
    }
    
    .movie {
        background-color: $white;
        padding: 0 $column-space $column-space $column-space;
        display: inline-block;
        width: $full-width;
        
        @include breakpoint(medium) {
            padding: 0 0 0 0;    
        }
    }
}	

.btn {
    @include font-size(18);
    font-weight: 700;
    color: $white;
    display: table;
    background-color: $blue;
    margin: $content-margin;
    padding: 10px 20px;
    border-radius: 6px;
    text-decoration: none;
    
    @include breakpoint(medium) {
        @include font-size(17);
        width: 100%;
    }
    
    .fa {
        @include breakpoint(medium) {
            float: right;
            padding-top: 3px;
        }
    }
    
    &:hover {
        background-color: lighten($blue, 5%);
        color: $white;
    }
    
    &.donate {
        font-family: "Nunito";
        background-color: $yellow;
        color: $blue;
        display: inline-block;
        text-decoration: none;
        margin: 0 0 $column-space 0;
        padding: 15px 20px;
        position: relative;
        width: 100%;
        
        &:hover {
            background-color: darken($yellow, 2%);
            color: darken($blue, 10%);
        }
        
        span.small {
            display: block;
            font-weight: 300;
            @include font-size(20);
        }
       
        span.big {
            font-weight: 400;
            display: block;
            @include font-size(30);
        }
        
        img {
            right: 10px;
            position: absolute;
            top: 10px;
        }
    }
}

.home {
    
    .block-wrapper {
        margin-bottom: $column-space;
    }


    #content-main .block-content{
        height: 165px;

        @include breakpoint(normal) {
            height: 200px;
        }

        @include breakpoint(medium) {
            height: auto;
        }
    }

    #content-main .block-home-content .block-content,
    .movie {
        height: 300px;
        overflow: hidden;

            @include breakpoint(normal) {
                 height: 400px;
             }

            @include breakpoint(normal) {
                height: auto;
            }
        }

    .block{
        @include breakpoint(medium) {
            width: 100%;
            height: auto;
        }
    }

    .block-content {
        background-color: $white;
        padding: 10px 20px;
        
        @include breakpoint(medium) {
            padding: 10px 0px;
        }
    }
    
}
