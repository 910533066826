$header-height: 10vh;

/* header container 
--------------------- */
header {
    background: $white linear-gradient(to right, $white 0%, $white 49%, $blue 50%, $blue 100%); 
    background-size: 100% 41px; 
    background-repeat: no-repeat;
	height: auto;
	position: relative;
    min-height: $header-height;
    width: $full-width;
    
    .header-wrapper {
        min-height: $header-height;
        margin: 0 auto;
        max-width: $breakpoint-large;
        width: $content-width;
        position: relative;
    }
    
    @include breakpoint(medium) {
        background: $white;
    }
}

/* logo 
--------------------- */
#logo {
   	display: inline-block;
	float: left;
	line-height: $header-height;
	height: $header-height;
	width: 20%;
    position: relative;
    z-index: 2;
    
    @include breakpoint(medium) {
        height: auto;
        padding: 0 20px;
        width: 60%;
        }
    
    @include breakpoint(small) {
        width: 100%;
        }
    
	a {
		display: block;
		margin-top: 25px;
        max-height: 100%;
		max-width: 100%;
		vertical-align: middle;
		
        img {
		    display: inline-block;
            vertical-align: middle;
			width: $full-width;
			}
    }
}

/* top bar 
--------------------- */
#header-bar {
    background-color: $blue;
    height: 41px;
    min-height: $header-height - 6;
    float: right;
    width: 80%;
    text-align: right;
    z-index: 1;
    
        @include breakpoint(medium) {
            display: none;
        }
    
    .circle-spoof {
        background-color: $white;
        display: inline-block;
        border-radius: 0 200px 200px 0;
        float: left;
        height: 100px;
        margin: -15px 0 0 -60px;
        width: 100px;
    }
    
    p {
        color: $white;
        line-height: 40px;
        margin: 0 0 0 $column-space;
        display: inline-block;
        font-size: 1.3rem;


        @include breakpoint(normal) {
            display: none;
        }

        .fa {
            color: $white;
            @include font-size(20);
        }
    }
}
