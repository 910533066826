/*
	jQuery.mmenu CSS
*/
/*
	jQuery.mmenu panels CSS
*/
.mm-menu.mm-horizontal > .mm-panel {
  -webkit-transition: left 0.4s ease;
  -moz-transition: left 0.4s ease;
  -ms-transition: left 0.4s ease;
  -o-transition: left 0.4s ease;
  transition: left 0.4s ease; }

.mm-menu .mm-hidden {
  display: none; }

.mm-wrapper {
  overflow-x: hidden;
  position: relative; }

.mm-menu {
  background: inherit;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0; }
  .mm-menu > .mm-panel {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    background: inherit;
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding: 20px;
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 0; }
    .mm-menu > .mm-panel.mm-opened {
      left: 0%; }
    .mm-menu > .mm-panel.mm-subopened {
      left: -40%; }
    .mm-menu > .mm-panel.mm-highest {
      z-index: 1; }
  .mm-menu .mm-list {
    padding: 20px 0; }
  .mm-menu > .mm-list {
    padding: 20px 0 40px 0; }

.mm-panel > .mm-list {
  margin-left: -20px;
  margin-right: -20px; }
  .mm-panel > .mm-list:first-child {
    padding-top: 0; }

.mm-list,
.mm-list > li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0; }

.mm-list {
  font: inherit;
  font-size: 14px; }
  .mm-list a,
  .mm-list a:hover {
    text-decoration: none; }
  .mm-list > li {
    position: relative; }
    .mm-list > li > a,
    .mm-list > li > span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: inherit;
      line-height: 20px;
      display: block;
      padding: 10px 10px 10px 20px;
      margin: 0; }
  .mm-list > li:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults):after {
    content: '';
    border-bottom-width: 1px;
    border-bottom-style: solid;
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0; }
  .mm-list > li:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults):after {
    width: auto;
    margin-left: 20px;
    position: relative;
    left: auto; }
  .mm-list a.mm-subopen {
    width: 40px;
    height: 100%;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2; }
    .mm-list a.mm-subopen:before {
      content: '';
      border-left-width: 1px;
      border-left-style: solid;
      display: block;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0; }
    .mm-list a.mm-subopen.mm-fullsubopen {
      width: 100%; }
      .mm-list a.mm-subopen.mm-fullsubopen:before {
        border-left: none; }
    .mm-list a.mm-subopen + a,
    .mm-list a.mm-subopen + span {
      padding-right: 5px;
      margin-right: 40px; }
  .mm-list > li.mm-selected > a.mm-subopen {
    background: transparent; }
  .mm-list > li.mm-selected > a.mm-fullsubopen + a,
  .mm-list > li.mm-selected > a.mm-fullsubopen + span {
    padding-right: 45px;
    margin-right: 0; }
  .mm-list a.mm-subclose {
    text-indent: 20px;
    padding-top: 30px;
    margin-top: -20px; }
  .mm-list > li.mm-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 10px;
    text-transform: uppercase;
    text-indent: 20px;
    line-height: 25px;
    padding-right: 5px; }
  .mm-list > li.mm-spacer {
    padding-top: 40px; }
    .mm-list > li.mm-spacer.mm-label {
      padding-top: 25px; }
  .mm-list a.mm-subopen:after,
  .mm-list a.mm-subclose:before {
    content: '';
    border: 2px solid transparent;
    display: block;
    width: 7px;
    height: 7px;
    margin-bottom: -5px;
    position: absolute;
    bottom: 50%;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  .mm-list a.mm-subopen:after {
    border-top: none;
    border-left: none;
    right: 18px; }
  .mm-list a.mm-subclose:before {
    border-right: none;
    border-bottom: none;
    margin-bottom: -15px;
    left: 22px; }

.mm-menu.mm-vertical .mm-list .mm-panel {
  display: none;
  padding: 10px 0 10px 10px; }
  .mm-menu.mm-vertical .mm-list .mm-panel li:last-child:after {
    border-color: transparent; }
.mm-menu.mm-vertical .mm-list li.mm-opened > .mm-panel {
  display: block; }
.mm-menu.mm-vertical .mm-list > li.mm-opened > a.mm-subopen {
  height: 40px; }
  .mm-menu.mm-vertical .mm-list > li.mm-opened > a.mm-subopen:after {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 16px;
    right: 16px; }

html.mm-opened .mm-page {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); }

.mm-ismenu {
  background: #333333;
  color: rgba(255, 255, 255, 0.6); }

.mm-menu .mm-list > li:after {
  border-color: rgba(0, 0, 0, 0.15); }
.mm-menu .mm-list > li > a.mm-subclose {
  background: rgba(0, 0, 0, 0.1);
  color: rgba(255, 255, 255, 0.3); }
.mm-menu .mm-list > li > a.mm-subopen:after, .mm-menu .mm-list > li > a.mm-subclose:before {
  border-color: rgba(255, 255, 255, 0.3); }
.mm-menu .mm-list > li > a.mm-subopen:before {
  border-color: rgba(0, 0, 0, 0.15); }
.mm-menu .mm-list > li.mm-selected > a:not(.mm-subopen),
.mm-menu .mm-list > li.mm-selected > span {
  background: rgba(0, 0, 0, 0.1); }
.mm-menu .mm-list > li.mm-label {
  background: rgba(255, 255, 255, 0.05); }
.mm-menu.mm-vertical .mm-list li.mm-opened > a.mm-subopen,
.mm-menu.mm-vertical .mm-list li.mm-opened > ul {
  background: rgba(255, 255, 255, 0.05); }

/*
	jQuery.mmenu offcanvas addon CSS
*/
.mm-page,
.mm-fixed-top,
.mm-fixed-bottom {
  -webkit-transition: none 0.4s ease;
  -moz-transition: none 0.4s ease;
  -ms-transition: none 0.4s ease;
  -o-transition: none 0.4s ease;
  transition: none 0.4s ease;
  -webkit-transition-property: top, right, bottom, left, border;
  -moz-transition-property: top, right, bottom, left, border;
  -ms-transition-property: top, right, bottom, left, border;
  -o-transition-property: top, right, bottom, left, border;
  transition-property: top, right, bottom, left, border; }

.mm-page,
#mm-blocker {
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.mm-page {
  border: 0px solid rgba(0, 0, 0, 0); }

html.mm-opening .mm-page {
  border: 0px solid rgba(1, 1, 1, 0); }

.mm-fixed-top,
.mm-fixed-bottom {
  position: fixed;
  left: 0; }

.mm-fixed-top {
  top: 0; }

.mm-fixed-bottom {
  bottom: 0; }

html.mm-opened {
  overflow: hidden;
  position: relative; }
  html.mm-opened body {
    overflow: hidden; }

html.mm-opened .mm-page {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  position: relative; }

html.mm-background .mm-page {
  background: inherit; }

#mm-blocker {
  background: url(data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==) transparent;
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999999; }

html.mm-opened #mm-blocker,
html.mm-blocking #mm-blocker {
  display: block; }

.mm-menu.mm-offcanvas {
  display: none;
  position: fixed; }
.mm-menu.mm-current {
  display: block; }

html.mm-opening .mm-page,
html.mm-opening #mm-blocker,
html.mm-opening .mm-fixed-top,
html.mm-opening .mm-fixed-bottom {
  left: 80%; }

.mm-menu {
  width: 80%; }

@media all and (max-width: 175px) {
  .mm-menu {
    width: 140px; }

  html.mm-opening .mm-page,
  html.mm-opening #mm-blocker,
  html.mm-opening .mm-fixed-top,
  html.mm-opening .mm-fixed-bottom {
    left: 140px; } }
@media all and (min-width: 550px) {
  .mm-menu {
    width: 440px; }

  html.mm-opening .mm-page,
  html.mm-opening #mm-blocker,
  html.mm-opening .mm-fixed-top,
  html.mm-opening .mm-fixed-bottom {
    left: 440px; } }
/*
	jQuery.mmenu counters addon CSS
*/
em.mm-counter {
  font: inherit;
  font-size: 14px;
  font-style: normal;
  text-indent: 0;
  line-height: 20px;
  display: block;
  margin-top: -10px;
  position: absolute;
  right: 40px;
  top: 50%; }
  em.mm-counter + a.mm-subopen {
    padding-left: 40px; }
    em.mm-counter + a.mm-subopen + a,
    em.mm-counter + a.mm-subopen + span {
      margin-right: 80px; }
  em.mm-counter + a.mm-fullsubopen {
    padding-left: 0; }

.mm-vertical em.mm-counter {
  top: 12px;
  margin-top: 0; }

.mm-nosubresults > em.mm-counter {
  display: none; }

.mm-menu em.mm-counter {
  color: rgba(255, 255, 255, 0.3); }

/*
	jQuery.mmenu dragOpen addon CSS
*/
html.mm-opened.mm-dragging .mm-menu,
html.mm-opened.mm-dragging .mm-page,
html.mm-opened.mm-dragging .mm-fixed-top,
html.mm-opened.mm-dragging .mm-fixed-bottom,
html.mm-opened.mm-dragging #mm-blocker {
  -webkit-transition-duration: 0s;
  -moz-transition-duration: 0s;
  -ms-transition-duration: 0s;
  -o-transition-duration: 0s;
  transition-duration: 0s; }

/*
	jQuery.mmenu header addon CSS
*/
.mm-header {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  background: inherit;
  border-bottom: 1px solid transparent;
  text-align: center;
  line-height: 20px;
  width: 100%;
  height: 60px;
  padding: 30px 40px 0 40px;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0; }
  .mm-header .mm-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    width: 100%;
    position: relative;
    z-index: 1; }
  .mm-header .mm-prev,
  .mm-header .mm-next {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;
    display: block;
    width: 40px;
    height: 100%;
    position: absolute;
    bottom: 0; }
    .mm-header .mm-prev:before,
    .mm-header .mm-next:before {
      content: '';
      border: 2px solid transparent;
      display: block;
      width: 7px;
      height: 7px;
      margin-bottom: -5px;
      position: absolute;
      bottom: 50%;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      margin-bottom: -15px; }
  .mm-header .mm-prev {
    left: 0; }
    .mm-header .mm-prev:before {
      border-right: none;
      border-bottom: none;
      left: 22px; }
  .mm-header .mm-next {
    right: 0; }
    .mm-header .mm-next:before {
      border-top: none;
      border-left: none;
      right: 18px; }

.mm-menu.mm-hassearch .mm-header {
  height: 50px;
  padding-top: 20px;
  top: 50px; }
  .mm-menu.mm-hassearch .mm-header .mm-prev:before,
  .mm-menu.mm-hassearch .mm-header .mm-mext:before {
    margin-bottom: -10px; }

.mm-menu.mm-hasheader li.mm-subtitle {
  display: none; }
.mm-menu.mm-hasheader > .mm-panel {
  padding-top: 80px; }
  .mm-menu.mm-hasheader > .mm-panel.mm-list {
    padding-top: 60px; }
  .mm-menu.mm-hasheader > .mm-panel > .mm-list:first-child {
    margin-top: -20px; }
.mm-menu.mm-hasheader.mm-hassearch > .mm-panel {
  padding-top: 120px; }
  .mm-menu.mm-hasheader.mm-hassearch > .mm-panel.mm-list {
    padding-top: 100px; }

.mm-menu .mm-header {
  border-color: rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.3); }
  .mm-menu .mm-header a:before {
    border-color: rgba(255, 255, 255, 0.3); }

/*
	jQuery.mmenu labels addon CSS
*/
.mm-menu.mm-fixedlabels .mm-list {
  background: inherit; }
  .mm-menu.mm-fixedlabels .mm-list > li.mm-label {
    background: inherit !important;
    opacity: 0.97;
    height: 25px;
    overflow: visible;
    position: relative;
    z-index: 1; }
    .mm-menu.mm-fixedlabels .mm-list > li.mm-label > div {
      background: inherit;
      width: 100%;
      position: absolute;
      left: 0; }
      .mm-menu.mm-fixedlabels .mm-list > li.mm-label > div > div {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; }
    .mm-menu.mm-fixedlabels .mm-list > li.mm-label.mm-spacer > div > div {
      padding-top: 25px; }

.mm-list > li.mm-label > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0; }
.mm-list > li.mm-label.mm-opened a.mm-subopen:after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  right: 17px; }
.mm-list > li.mm-collapsed {
  display: none; }

.mm-menu .mm-list li.mm-label > div > div {
  background: rgba(255, 255, 255, 0.05); }

/*
	jQuery.mmenu searchfield addon CSS
*/
.mm-search,
.mm-search input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box; }

.mm-list > li.mm-search {
  padding: 10px;
  margin-top: -20px; }
.mm-list > li.mm-subtitle + li.mm-search {
  margin-top: 0; }

div.mm-panel > div.mm-search {
  padding: 0 0 10px 0; }

.mm-menu.mm-hasheader .mm-list > li.mm-search {
  margin-top: 0; }

.mm-search {
  background: inherit;
  width: 100%;
  padding: 10px;
  position: relative;
  top: 0;
  z-index: 2; }
  .mm-search input {
    border: none;
    border-radius: 30px;
    font: inherit;
    font-size: 14px;
    line-height: 30px;
    outline: none;
    display: block;
    width: 100%;
    height: 30px;
    margin: 0;
    padding: 0 10px; }

.mm-menu .mm-noresultsmsg {
  text-align: center;
  font-size: 21px;
  display: none;
  padding: 60px 0; }
  .mm-menu .mm-noresultsmsg:after {
    border: none !important; }

.mm-noresults .mm-noresultsmsg {
  display: block; }

.mm-menu li.mm-nosubresults > a.mm-subopen {
  display: none; }
  .mm-menu li.mm-nosubresults > a.mm-subopen + a,
  .mm-menu li.mm-nosubresults > a.mm-subopen + span {
    padding-right: 10px; }
.mm-menu.mm-hassearch > .mm-panel {
  padding-top: 70px; }
  .mm-menu.mm-hassearch > .mm-panel > .mm-list:first-child {
    margin-top: -20px; }
.mm-menu.mm-hasheader > .mm-panel > div.mm-search:first-child {
  margin-top: -10px; }
  .mm-menu.mm-hasheader > .mm-panel > div.mm-search:first-child + .mm-list {
    padding-top: 0; }

.mm-menu .mm-search input {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.6); }
.mm-menu .mm-noresultsmsg {
  color: rgba(255, 255, 255, 0.3); }

/*
	jQuery.mmenu toggles addon CSS
*/
label.mm-toggle {
  border-radius: 30px;
  width: 50px;
  height: 30px;
  margin: -15px 0 0 0;
  position: absolute;
  top: 50%;
  z-index: 1; }
  label.mm-toggle div {
    border-radius: 30px;
    width: 28px;
    height: 28px;
    margin: 1px; }

input.mm-toggle {
  position: absolute;
  left: -10000px; }
  input.mm-toggle:checked ~ label.mm-toggle div {
    float: right; }

label.mm-toggle {
  right: 20px; }
  label.mm-toggle + a,
  label.mm-toggle + span {
    margin-right: 70px; }

a.mm-subopen + label.mm-toggle {
  right: 50px; }
  a.mm-subopen + label.mm-toggle + a,
  a.mm-subopen + label.mm-toggle + span {
    margin-right: 100px; }

em.mm-counter + a.mm-subopen + label.mm-toggle {
  right: 90px; }
  em.mm-counter + a.mm-subopen + label.mm-toggle + a,
  em.mm-counter + a.mm-subopen + label.mm-toggle + span {
    margin-right: 140px; }

.mm-menu label.mm-toggle {
  background: rgba(0, 0, 0, 0.15); }
  .mm-menu label.mm-toggle div {
    background: #333333; }
.mm-menu input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963; }

/*
	jQuery.mmenu effects extension CSS
*/
html.mm-slide .mm-menu {
  -webkit-transition: -webkit-transform 0.4s ease;
  -moz-transition: -moz-transform 0.4s ease;
  -o-transition: -o-transform 0.4s ease;
  transition: transform 0.4s ease; }
html.mm-slide.mm-opened .mm-menu {
  -webkit-transform: translateX(-40%);
  -moz-transform: translateX(-40%);
  -ms-transform: translateX(-40%);
  -o-transform: translateX(-40%);
  transform: translateX(-40%); }
html.mm-slide.mm-opening .mm-menu {
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%); }
html.mm-slide.mm-right.mm-opened .mm-menu {
  -webkit-transform: translateX(40%);
  -moz-transform: translateX(40%);
  -ms-transform: translateX(40%);
  -o-transform: translateX(40%);
  transform: translateX(40%); }
html.mm-slide.mm-right.mm-opening .mm-menu {
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%); }
html.mm-slide.mm-top.mm-opened .mm-menu {
  -webkit-transform: translateY(-40%);
  -moz-transform: translateY(-40%);
  -ms-transform: translateY(-40%);
  -o-transform: translateY(-40%);
  transform: translateY(-40%); }
html.mm-slide.mm-top.mm-opening .mm-menu {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%); }
html.mm-slide.mm-bottom.mm-opened .mm-menu {
  -webkit-transform: translateY(40%);
  -moz-transform: translateY(40%);
  -ms-transform: translateY(40%);
  -o-transform: translateY(40%);
  transform: translateY(40%); }
html.mm-slide.mm-bottom.mm-opening .mm-menu {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%); }

html.mm-zoom-menu .mm-menu {
  -webkit-transition: -webkit-transform 0.4s ease;
  -moz-transition: -moz-transform 0.4s ease;
  -o-transition: -o-transform 0.4s ease;
  transition: transform 0.4s ease; }
html.mm-zoom-menu.mm-opened .mm-menu {
  -webkit-transform: scale(0.7, 0.7) translateX(-40%);
  -moz-transform: scale(0.7, 0.7) translateX(-40%);
  -ms-transform: scale(0.7, 0.7) translateX(-40%);
  -o-transform: scale(0.7, 0.7) translateX(-40%);
  transform: scale(0.7, 0.7) translateX(-40%);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center; }
html.mm-zoom-menu.mm-opening .mm-menu {
  -webkit-transform: scale(1, 1) translateX(0%);
  -moz-transform: scale(1, 1) translateX(0%);
  -ms-transform: scale(1, 1) translateX(0%);
  -o-transform: scale(1, 1) translateX(0%);
  transform: scale(1, 1) translateX(0%); }
html.mm-zoom-menu.mm-right.mm-opened .mm-menu {
  -webkit-transform: scale(0.7, 0.7) translateX(40%);
  -moz-transform: scale(0.7, 0.7) translateX(40%);
  -ms-transform: scale(0.7, 0.7) translateX(40%);
  -o-transform: scale(0.7, 0.7) translateX(40%);
  transform: scale(0.7, 0.7) translateX(40%);
  -webkit-transform-origin: right center;
  -moz-transform-origin: right center;
  -ms-transform-origin: right center;
  -o-transform-origin: right center;
  transform-origin: right center; }
html.mm-zoom-menu.mm-right.mm-opening .mm-menu {
  -webkit-transform: scale(1, 1) translateX(0%);
  -moz-transform: scale(1, 1) translateX(0%);
  -ms-transform: scale(1, 1) translateX(0%);
  -o-transform: scale(1, 1) translateX(0%);
  transform: scale(1, 1) translateX(0%); }
html.mm-zoom-menu.mm-top.mm-opened .mm-menu {
  -webkit-transform: scale(0.7, 0.7) translateY(-40%);
  -moz-transform: scale(0.7, 0.7) translateY(-40%);
  -ms-transform: scale(0.7, 0.7) translateY(-40%);
  -o-transform: scale(0.7, 0.7) translateY(-40%);
  transform: scale(0.7, 0.7) translateY(-40%);
  -webkit-transform-origin: center top;
  -moz-transform-origin: center top;
  -ms-transform-origin: center top;
  -o-transform-origin: center top;
  transform-origin: center top; }
html.mm-zoom-menu.mm-top.mm-opening .mm-menu {
  -webkit-transform: scale(1, 1) translateY(0%);
  -moz-transform: scale(1, 1) translateY(0%);
  -ms-transform: scale(1, 1) translateY(0%);
  -o-transform: scale(1, 1) translateY(0%);
  transform: scale(1, 1) translateY(0%); }
html.mm-zoom-menu.mm-bottom.mm-opened .mm-menu {
  -webkit-transform: scale(0.7, 0.7) translateY(40%);
  -moz-transform: scale(0.7, 0.7) translateY(40%);
  -ms-transform: scale(0.7, 0.7) translateY(40%);
  -o-transform: scale(0.7, 0.7) translateY(40%);
  transform: scale(0.7, 0.7) translateY(40%);
  -webkit-transform-origin: center bottom;
  -moz-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  -o-transform-origin: center bottom;
  transform-origin: center bottom; }
html.mm-zoom-menu.mm-bottom.mm-opening .mm-menu {
  -webkit-transform: scale(1, 1) translateY(0%);
  -moz-transform: scale(1, 1) translateY(0%);
  -ms-transform: scale(1, 1) translateY(0%);
  -o-transform: scale(1, 1) translateY(0%);
  transform: scale(1, 1) translateY(0%); }

html.mm-zoom-page .mm-page {
  -webkit-transition-property: -webkit-transform, top, right, bottom, left, border;
  -moz-transition-property: -moz-transform, top, right, bottom, left, border;
  -ms-transition-property: -ms-transform, top, right, bottom, left, border;
  -o-transition-property: -o-transform, top, right, bottom, left, border;
  transition-property: transform, top, right, bottom, left, border; }
html.mm-zoom-page.mm-opened .mm-page {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center; }
html.mm-zoom-page.mm-opening .mm-page {
  -webkit-transform: scale(1.5, 1.5);
  -moz-transform: scale(1.5, 1.5);
  -ms-transform: scale(1.5, 1.5);
  -o-transform: scale(1.5, 1.5);
  transform: scale(1.5, 1.5); }
html.mm-zoom-page.mm-right.mm-opened .mm-page {
  -webkit-transform-origin: right center;
  -moz-transform-origin: right center;
  -ms-transform-origin: right center;
  -o-transform-origin: right center;
  transform-origin: right center; }
html.mm-zoom-page.mm-top.mm-opened .mm-page {
  -webkit-transform-origin: center top;
  -moz-transform-origin: center top;
  -ms-transform-origin: center top;
  -o-transform-origin: center top;
  transform-origin: center top; }
html.mm-zoom-page.mm-bottom.mm-opened .mm-page {
  -webkit-transform-origin: center bottom;
  -moz-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  -o-transform-origin: center bottom;
  transform-origin: center bottom; }

html.mm-zoom-panels .mm-menu.mm-horizontal > .mm-panel {
  -webkit-transform: scale(1.5, 1.5);
  -moz-transform: scale(1.5, 1.5);
  -ms-transform: scale(1.5, 1.5);
  -o-transform: scale(1.5, 1.5);
  transform: scale(1.5, 1.5);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition-property: -webkit-transform, left;
  -moz-transition-property: -moz-transform, left;
  -ms-transition-property: -ms-transform, left;
  -o-transition-property: -o-transform, left;
  transition-property: transform, left; }
  html.mm-zoom-panels .mm-menu.mm-horizontal > .mm-panel.mm-opened {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); }
    html.mm-zoom-panels .mm-menu.mm-horizontal > .mm-panel.mm-opened.mm-subopened {
      -webkit-transform: scale(0.7, 0.7);
      -moz-transform: scale(0.7, 0.7);
      -ms-transform: scale(0.7, 0.7);
      -o-transform: scale(0.7, 0.7);
      transform: scale(0.7, 0.7); }

/*
	jQuery.mmenu fullscreen extension CSS
*/
html.mm-opening.mm-fullscreen .mm-page,
html.mm-opening.mm-fullscreen #mm-blocker,
html.mm-opening.mm-fullscreen .mm-fixed-top,
html.mm-opening.mm-fullscreen .mm-fixed-bottom {
  left: 100%; }

.mm-menu.mm-fullscreen {
  width: 100%; }

@media all and (max-width: 140px) {
  .mm-menu.mm-fullscreen {
    width: 140px; }

  html.mm-opening.mm-fullscreen .mm-page,
  html.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-opening.mm-fullscreen .mm-fixed-top,
  html.mm-opening.mm-fullscreen .mm-fixed-bottom {
    left: 140px; } }
@media all and (min-width: 10000px) {
  .mm-menu.mm-fullscreen {
    width: 10000px; }

  html.mm-opening.mm-fullscreen .mm-page,
  html.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-opening.mm-fullscreen .mm-fixed-top,
  html.mm-opening.mm-fullscreen .mm-fixed-bottom {
    left: 10000px; } }
.mm-menu.mm-top.mm-fullscreen {
  height: 100%; }

html.mm-top.mm-opening.mm-fullscreen .mm-page,
html.mm-top.mm-opening.mm-fullscreen #mm-blocker,
html.mm-top.mm-opening.mm-fullscreen .mm-fixed-top {
  top: 100%; }
html.mm-top.mm-opening.mm-fullscreen .mm-fixed-bottom {
  bottom: -100%; }

@media all and (max-height: 140px) {
  .mm-menu.mm-top.mm-fullscreen {
    height: 140px; }

  html.mm-top.mm-opening.mm-fullscreen .mm-page,
  html.mm-top.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-top.mm-opening.mm-fullscreen .mm-fixed-top {
    top: 140px; }
  html.mm-top.mm-opening.mm-fullscreen .mm-fixed-bottom {
    bottom: -140px; } }
@media all and (min-height: 10000px) {
  .mm-menu.mm-top.mm-fullscreen {
    height: 10000px; }

  html.mm-top.mm-opening.mm-fullscreen .mm-page,
  html.mm-top.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-top.mm-opening.mm-fullscreen .mm-fixed-top {
    top: 10000px; }
  html.mm-top.mm-opening.mm-fullscreen .mm-fixed-bottom {
    bottom: -10000px; } }
.mm-menu.mm-right.mm-fullscreen {
  width: 100%; }

html.mm-right.mm-opening.mm-fullscreen .mm-page,
html.mm-right.mm-opening.mm-fullscreen #mm-blocker,
html.mm-right.mm-opening.mm-fullscreen .mm-fixed-top,
html.mm-right.mm-opening.mm-fullscreen .mm-fixed-bottom {
  right: 100%; }

@media all and (max-width: 140px) {
  .mm-menu.mm-right.mm-fullscreen {
    width: 140px; }

  html.mm-right.mm-opening.mm-fullscreen .mm-page,
  html.mm-right.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-right.mm-opening.mm-fullscreen .mm-fixed-top,
  html.mm-right.mm-opening.mm-fullscreen .mm-fixed-bottom {
    right: 140px; } }
@media all and (min-width: 10000px) {
  .mm-menu.mm-right.mm-fullscreen {
    width: 10000px; }

  html.mm-right.mm-opening.mm-fullscreen .mm-page,
  html.mm-right.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-right.mm-opening.mm-fullscreen .mm-fixed-top,
  html.mm-right.mm-opening.mm-fullscreen .mm-fixed-bottom {
    right: 10000px; } }
.mm-menu.mm-bottom.mm-fullscreen {
  height: 100%; }

html.mm-bottom.mm-opening.mm-fullscreen .mm-page,
html.mm-bottom.mm-opening.mm-fullscreen #mm-blocker,
html.mm-bottom.mm-opening.mm-fullscreen .mm-fixed-bottom {
  bottom: 100%; }
html.mm-bottom.mm-opening.mm-fullscreen .mm-fixed-top {
  top: -100%; }

@media all and (max-height: 140px) {
  .mm-menu.mm-bottom.mm-fullscreen {
    height: 140px; }

  html.mm-bottom.mm-opening.mm-fullscreen .mm-page,
  html.mm-bottom.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-bottom.mm-opening.mm-fullscreen .mm-fixed-bottom {
    bottom: 140px; }
  html.mm-bottom.mm-opening.mm-fullscreen .mm-fixed-top {
    top: -140px; } }
@media all and (min-height: 10000px) {
  .mm-menu.mm-bottom.mm-fullscreen {
    height: 10000px; }

  html.mm-bottom.mm-opening.mm-fullscreen .mm-page,
  html.mm-bottom.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-bottom.mm-opening.mm-fullscreen .mm-fixed-bottom {
    bottom: 10000px; }
  html.mm-bottom.mm-opening.mm-fullscreen .mm-fixed-top {
    top: -10000px; } }
.mm-menu.mm-fullscreen.mm-front, .mm-menu.mm-fullscreen.mm-next {
  left: -100%; }

@media all and (max-width: 140px) {
  .mm-menu.mm-fullscreen.mm-front, .mm-menu.mm-fullscreen.mm-next {
    left: -140px; } }
@media all and (min-width: 10000px) {
  .mm-menu.mm-fullscreen.mm-front, .mm-menu.mm-fullscreen.mm-next {
    left: -10000px; } }
.mm-menu.mm-top.mm-fullscreen.mm-front, .mm-menu.mm-top.mm-fullscreen.mm-next {
  top: -100%; }

@media all and (max-height: 140px) {
  .mm-menu.mm-top.mm-fullscreen.mm-front, .mm-menu.mm-top.mm-fullscreen.mm-next {
    top: -140px; } }
@media all and (min-height: 10000px) {
  .mm-menu.mm-top.mm-fullscreen.mm-front, .mm-menu.mm-top.mm-fullscreen.mm-next {
    top: -10000px; } }
.mm-menu.mm-right.mm-fullscreen.mm-front, .mm-menu.mm-right.mm-fullscreen.mm-next {
  right: -100%; }

@media all and (max-width: 140px) {
  .mm-menu.mm-right.mm-fullscreen.mm-front, .mm-menu.mm-right.mm-fullscreen.mm-next {
    right: -140px; } }
@media all and (min-width: 10000px) {
  .mm-menu.mm-right.mm-fullscreen.mm-front, .mm-menu.mm-right.mm-fullscreen.mm-next {
    right: -10000px; } }
.mm-menu.mm-bottom.mm-fullscreen.mm-front, .mm-menu.mm-bottom.mm-fullscreen.mm-next {
  bottom: -100%; }

@media all and (max-height: 140px) {
  .mm-menu.mm-bottom.mm-fullscreen.mm-front, .mm-menu.mm-bottom.mm-fullscreen.mm-next {
    bottom: -140px; } }
@media all and (min-height: 10000px) {
  .mm-menu.mm-bottom.mm-fullscreen.mm-front, .mm-menu.mm-bottom.mm-fullscreen.mm-next {
    bottom: -10000px; } }
html.mm-front .mm-fixed-top,
html.mm-front .mm-fixed-bottom,
html.mm-opening.mm-front .mm-fixed-top,
html.mm-opening.mm-front .mm-fixed-bottom {
  left: 0;
  right: auto; }
html.mm-front .mm-fixed-top,
html.mm-opening.mm-front .mm-fixed-top {
  top: 0; }
html.mm-front .mm-fixed-bottom,
html.mm-opening.mm-front .mm-fixed-bottom {
  bottom: 0; }

html.mm-opened.mm-fullscreen .mm-page {
  box-shadow: none !important; }

/*
	jQuery.mmenu position extension CSS
*/
.mm-menu.mm-top {
  width: 100%; }

html.mm-top.mm-opened .mm-page,
html.mm-top.mm-opened #mm-blocker {
  top: 0%; }

html.mm-top.mm-opened.mm-opening .mm-page,
html.mm-top.mm-opened.mm-opening #mm-blocker,
html.mm-top.mm-opened.mm-opening .mm-fixed-top,
html.mm-top.mm-opened.mm-opening .mm-fixed-bottom {
  left: 0; }

.mm-menu.mm-right {
  left: auto;
  right: 0; }

html.mm-right.mm-opened .mm-page,
html.mm-right.mm-opened #mm-blocker,
html.mm-right.mm-opened .mm-fixed-top,
html.mm-right.mm-opened .mm-fixed-bottom {
  left: auto;
  right: 0%; }

html.mm-right.mm-opened.mm-opening .mm-page,
html.mm-right.mm-opened.mm-opening #mm-blocker,
html.mm-right.mm-opened.mm-opening .mm-fixed-top,
html.mm-right.mm-opened.mm-opening .mm-fixed-bottom {
  left: auto; }

.mm-menu.mm-bottom {
  width: 100%;
  top: auto;
  bottom: 0; }

html.mm-bottom.mm-opened .mm-page,
html.mm-bottom.mm-opened #mm-blocker {
  bottom: 0%;
  top: auto; }

html.mm-bottom.mm-opened.mm-opening .mm-page,
html.mm-bottom.mm-opened.mm-opening #mm-blocker,
html.mm-bottom.mm-opened.mm-opening .mm-fixed-top,
html.mm-bottom.mm-opened.mm-opening .mm-fixed-bottom {
  top: auto;
  left: 0; }

.mm-menu.mm-top {
  height: 80%; }

html.mm-top.mm-opening .mm-page,
html.mm-top.mm-opening #mm-blocker,
html.mm-top.mm-opening .mm-fixed-top {
  top: 80%; }
html.mm-top.mm-opening .mm-fixed-bottom {
  bottom: -80%; }

@media all and (max-height: 175px) {
  .mm-menu.mm-top {
    height: 140px; }

  html.mm-top.mm-opening .mm-page,
  html.mm-top.mm-opening #mm-blocker,
  html.mm-top.mm-opening .mm-fixed-top {
    top: 140px; }
  html.mm-top.mm-opening .mm-fixed-bottom {
    bottom: -140px; } }
@media all and (min-height: 1100px) {
  .mm-menu.mm-top {
    height: 880px; }

  html.mm-top.mm-opening .mm-page,
  html.mm-top.mm-opening #mm-blocker,
  html.mm-top.mm-opening .mm-fixed-top {
    top: 880px; }
  html.mm-top.mm-opening .mm-fixed-bottom {
    bottom: -880px; } }
.mm-menu.mm-right {
  width: 80%; }

html.mm-right.mm-opening .mm-page,
html.mm-right.mm-opening #mm-blocker,
html.mm-right.mm-opening .mm-fixed-top,
html.mm-right.mm-opening .mm-fixed-bottom {
  right: 80%; }

@media all and (max-width: 175px) {
  .mm-menu.mm-right {
    width: 140px; }

  html.mm-right.mm-opening .mm-page,
  html.mm-right.mm-opening #mm-blocker,
  html.mm-right.mm-opening .mm-fixed-top,
  html.mm-right.mm-opening .mm-fixed-bottom {
    right: 140px; } }
@media all and (min-width: 550px) {
  .mm-menu.mm-right {
    width: 440px; }

  html.mm-right.mm-opening .mm-page,
  html.mm-right.mm-opening #mm-blocker,
  html.mm-right.mm-opening .mm-fixed-top,
  html.mm-right.mm-opening .mm-fixed-bottom {
    right: 440px; } }
.mm-menu.mm-bottom {
  height: 80%; }

html.mm-bottom.mm-opening .mm-page,
html.mm-bottom.mm-opening #mm-blocker,
html.mm-bottom.mm-opening .mm-fixed-bottom {
  bottom: 80%; }
html.mm-bottom.mm-opening .mm-fixed-top {
  top: -80%; }

@media all and (max-height: 175px) {
  .mm-menu.mm-bottom {
    height: 140px; }

  html.mm-bottom.mm-opening .mm-page,
  html.mm-bottom.mm-opening #mm-blocker,
  html.mm-bottom.mm-opening .mm-fixed-bottom {
    bottom: 140px; }
  html.mm-bottom.mm-opening .mm-fixed-top {
    top: -140px; } }
@media all and (min-height: 1100px) {
  .mm-menu.mm-bottom {
    height: 880px; }

  html.mm-bottom.mm-opening .mm-page,
  html.mm-bottom.mm-opening #mm-blocker,
  html.mm-bottom.mm-opening .mm-fixed-bottom {
    bottom: 880px; }
  html.mm-bottom.mm-opening .mm-fixed-top {
    top: -880px; } }
/*
	jQuery.mmenu z-position extension CSS
*/
html.mm-front.mm-opened .mm-page {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important; }

.mm-menu.mm-front,
.mm-menu.mm-next {
  -webkit-transition: none 0.4s ease;
  -moz-transition: none 0.4s ease;
  -ms-transition: none 0.4s ease;
  -o-transition: none 0.4s ease;
  transition: none 0.4s ease;
  -webkit-transition-property: top, right, bottom, left, -webkit-transform;
  -moz-transition-property: top, right, bottom, left, -moz-transform;
  -ms-transition-property: top, right, bottom, left, -o-transform;
  -o-transition-property: top, right, bottom, left, -o-transform;
  transition-property: top, right, bottom, left, transform; }

html.mm-front .mm-page,
html.mm-front #mm-blocker {
  z-index: 0; }

.mm-menu.mm-front {
  z-index: 1;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5); }

html.mm-opened.mm-next .mm-page {
  box-shadow: none; }

html.mm-opening .mm-menu.mm-front, html.mm-opening .mm-menu.mm-next {
  left: 0%; }

.mm-menu.mm-top.mm-front, .mm-menu.mm-top.mm-next {
  left: 0; }

html.mm-opening .mm-menu.mm-top.mm-front, html.mm-opening .mm-menu.mm-top.mm-next {
  left: 0;
  top: 0%; }

.mm-menu.mm-right.mm-front, .mm-menu.mm-right.mm-next {
  left: auto; }

html.mm-opening .mm-menu.mm-right.mm-front, html.mm-opening .mm-menu.mm-right.mm-next {
  left: auto;
  right: 0%; }

.mm-menu.mm-bottom.mm-front, .mm-menu.mm-bottom.mm-next {
  top: auto;
  left: 0; }

html.mm-opening .mm-menu.mm-bottom.mm-front, html.mm-opening .mm-menu.mm-bottom.mm-next {
  left: 0;
  bottom: 0%; }

.mm-menu.mm-front, .mm-menu.mm-next {
  left: -80%; }

@media all and (max-width: 175px) {
  .mm-menu.mm-front, .mm-menu.mm-next {
    left: -140px; } }
@media all and (min-width: 550px) {
  .mm-menu.mm-front, .mm-menu.mm-next {
    left: -440px; } }
.mm-menu.mm-top.mm-front, .mm-menu.mm-top.mm-next {
  top: -80%; }

@media all and (max-height: 175px) {
  .mm-menu.mm-top.mm-front, .mm-menu.mm-top.mm-next {
    top: -140px; } }
@media all and (min-height: 1100px) {
  .mm-menu.mm-top.mm-front, .mm-menu.mm-top.mm-next {
    top: -880px; } }
.mm-menu.mm-right.mm-front, .mm-menu.mm-right.mm-next {
  right: -80%; }

@media all and (max-width: 175px) {
  .mm-menu.mm-right.mm-front, .mm-menu.mm-right.mm-next {
    right: -140px; } }
@media all and (min-width: 550px) {
  .mm-menu.mm-right.mm-front, .mm-menu.mm-right.mm-next {
    right: -440px; } }
.mm-menu.mm-bottom.mm-front, .mm-menu.mm-bottom.mm-next {
  bottom: -80%; }

@media all and (max-height: 175px) {
  .mm-menu.mm-bottom.mm-front, .mm-menu.mm-bottom.mm-next {
    bottom: -140px; } }
@media all and (min-height: 1100px) {
  .mm-menu.mm-bottom.mm-front, .mm-menu.mm-bottom.mm-next {
    bottom: -880px; } }
html.mm-front .mm-fixed-top,
html.mm-front .mm-fixed-bottom,
html.mm-opening.mm-front .mm-fixed-top,
html.mm-opening.mm-front .mm-fixed-bottom {
  left: 0;
  right: auto; }
html.mm-front .mm-fixed-top,
html.mm-opening.mm-front .mm-fixed-top {
  top: 0; }
html.mm-front .mm-fixed-bottom,
html.mm-opening.mm-front .mm-fixed-bottom {
  bottom: 0; }

/*
	jQuery.mmenu themes extension CSS
*/
html.mm-opened.mm-light .mm-page {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }

.mm-ismenu.mm-light {
  background: #f3f3f3;
  color: rgba(0, 0, 0, 0.6); }

.mm-menu.mm-light .mm-list > li:after {
  border-color: rgba(0, 0, 0, 0.1); }
.mm-menu.mm-light .mm-list > li > a.mm-subclose {
  background: rgba(255, 255, 255, 0.6);
  color: rgba(0, 0, 0, 0.3); }
.mm-menu.mm-light .mm-list > li > a.mm-subopen:after, .mm-menu.mm-light .mm-list > li > a.mm-subclose:before {
  border-color: rgba(0, 0, 0, 0.3); }
.mm-menu.mm-light .mm-list > li > a.mm-subopen:before {
  border-color: rgba(0, 0, 0, 0.1); }
.mm-menu.mm-light .mm-list > li.mm-selected > a:not(.mm-subopen),
.mm-menu.mm-light .mm-list > li.mm-selected > span {
  background: rgba(255, 255, 255, 0.6); }
.mm-menu.mm-light .mm-list > li.mm-label {
  background: rgba(0, 0, 0, 0.03); }
.mm-menu.mm-light.mm-vertical .mm-list li.mm-opened > a.mm-subopen,
.mm-menu.mm-light.mm-vertical .mm-list li.mm-opened > ul {
  background: rgba(0, 0, 0, 0.03); }

.mm-menu.mm-light .mm-search input {
  background: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.6); }
.mm-menu.mm-light .mm-noresultsmsg {
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-light em.mm-counter {
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-light .mm-list li.mm-label > div > div {
  background: rgba(0, 0, 0, 0.03); }

.mm-menu.mm-light .mm-header {
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.3); }
  .mm-menu.mm-light .mm-header a:before {
    border-color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-light label.mm-toggle {
  background: rgba(0, 0, 0, 0.1); }
  .mm-menu.mm-light label.mm-toggle div {
    background: #f3f3f3; }
.mm-menu.mm-light input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963; }

html.mm-opened.mm-white .mm-page {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }

.mm-ismenu.mm-white {
  background: white;
  color: rgba(0, 0, 0, 0.6); }

.mm-menu.mm-white .mm-list > li:after {
  border-color: rgba(0, 0, 0, 0.1); }
.mm-menu.mm-white .mm-list > li > a.mm-subclose {
  background: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.3); }
.mm-menu.mm-white .mm-list > li > a.mm-subopen:after, .mm-menu.mm-white .mm-list > li > a.mm-subclose:before {
  border-color: rgba(0, 0, 0, 0.3); }
.mm-menu.mm-white .mm-list > li > a.mm-subopen:before {
  border-color: rgba(0, 0, 0, 0.1); }
.mm-menu.mm-white .mm-list > li.mm-selected > a:not(.mm-subopen),
.mm-menu.mm-white .mm-list > li.mm-selected > span {
  background: rgba(0, 0, 0, 0.06); }
.mm-menu.mm-white .mm-list > li.mm-label {
  background: rgba(0, 0, 0, 0.03); }
.mm-menu.mm-white.mm-vertical .mm-list li.mm-opened > a.mm-subopen,
.mm-menu.mm-white.mm-vertical .mm-list li.mm-opened > ul {
  background: rgba(0, 0, 0, 0.03); }

.mm-menu.mm-white .mm-search input {
  background: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.6); }
.mm-menu.mm-white .mm-noresultsmsg {
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-white em.mm-counter {
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-white .mm-list li.mm-label > div > div {
  background: rgba(0, 0, 0, 0.03); }

.mm-menu.mm-white .mm-header {
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.3); }
  .mm-menu.mm-white .mm-header a:before {
    border-color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-white label.mm-toggle {
  background: rgba(0, 0, 0, 0.1); }
  .mm-menu.mm-white label.mm-toggle div {
    background: white; }
.mm-menu.mm-white input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963; }

html.mm-opened.mm-black .mm-page {
  box-shadow: none; }

.mm-ismenu.mm-black {
  background: black;
  color: rgba(255, 255, 255, 0.6); }

.mm-menu.mm-black .mm-list > li:after {
  border-color: rgba(255, 255, 255, 0.2); }
.mm-menu.mm-black .mm-list > li > a.mm-subclose {
  background: rgba(255, 255, 255, 0.25);
  color: rgba(255, 255, 255, 0.3); }
.mm-menu.mm-black .mm-list > li > a.mm-subopen:after, .mm-menu.mm-black .mm-list > li > a.mm-subclose:before {
  border-color: rgba(255, 255, 255, 0.3); }
.mm-menu.mm-black .mm-list > li > a.mm-subopen:before {
  border-color: rgba(255, 255, 255, 0.2); }
.mm-menu.mm-black .mm-list > li.mm-selected > a:not(.mm-subopen),
.mm-menu.mm-black .mm-list > li.mm-selected > span {
  background: rgba(255, 255, 255, 0.25); }
.mm-menu.mm-black .mm-list > li.mm-label {
  background: rgba(255, 255, 255, 0.15); }
.mm-menu.mm-black.mm-vertical .mm-list li.mm-opened > a.mm-subopen,
.mm-menu.mm-black.mm-vertical .mm-list li.mm-opened > ul {
  background: rgba(255, 255, 255, 0.15); }

.mm-menu.mm-black .mm-search input {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.6); }
.mm-menu.mm-black .mm-noresultsmsg {
  color: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-black em.mm-counter {
  color: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-black .mm-list li.mm-label > div > div {
  background: rgba(255, 255, 255, 0.15); }

.mm-menu.mm-black .mm-header {
  border-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.3); }
  .mm-menu.mm-black .mm-header a:before {
    border-color: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-black label.mm-toggle {
  background: rgba(255, 255, 255, 0.2); }
  .mm-menu.mm-black label.mm-toggle div {
    background: black; }
.mm-menu.mm-black input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963; }
