/* Typography
--------------------- */

/* Paragraph
--------------------- */
p {
	color: $paragraph;
	margin: $content-margin;
	}

/* Hyperlinks 
--------------------- */	
a {
	color: $link-idle;
    font-weight: 600;
	text-decoration: none;
	
	&:hover {
	    color: $link-hover;
		text-decoration: none;
    }
}
	
/* Text styles 
--------------------- */
.light {
    font-weight: 300;
    }

.bold {
	font-weight: bold;
	}
	
.bold600 {
	font-weight: 600;
	}

.italic {
	font-style: italic;
	}

/* Headers
--------------------- */
@for $index from 1 through 6 {
  h#{$index}{
    color: #494949;
    font-weight: normal;
    margin: 20px 0 10px 0;
  }
}
	
h1 {
	@include font-size(34);
	@include line-height(39);
	}
	
h2 {
    @include font-size(27);
	@include line-height(32);
    
     .grid & {
        margin: $content-margin;     
     }
}
	
h3 {
    @include font-size(18);
	@include line-height(29);
	}

h4 {
	@include font-size(16);
	@include line-height(26);
	}

h5 {
	@include font-size(14);
	@include line-height(23);
	}

h6 {
	@include font-size(12);
	@include line-height(19);
	}