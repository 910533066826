/*
=================================================================
                        Breakpoints
=================================================================
*/

$breakpoint-small: 460px;
$breakpoint-medium: 920px;
$breakpoint-normal: 1024px;
$breakpoint-large: 1200px;
$breakpoint-wide: 1920px;
$full-width: 100%;
$content-width: 98%;

@mixin breakpoint($point) {
  @if $point == small {
    @media (max-width: $breakpoint-small) { @content; }
  } @else if $point == medium {
    @media (max-width: $breakpoint-medium) { @content; }
  } @else if $point == large {
    @media (max-width: $breakpoint-large) { @content; }
  } @else if $point == normal {
    @media (max-width: $breakpoint-normal) { @content; }
  } @else if $point == wide {
    @media (max-width: $breakpoint-wide) { @content; }
  } @else {
    @warn 'Not a valid breakpoint name.';
  }
}