#faq-overview {
  list-style: none;

  .faq-item{
    margin: 10px 0;


    .faq-question{
      @include font-size(20);
      @include line-height(22);
    }

    .faq-answer{
      height: 0;
      overflow: hidden;

      @include transition(height , 1.4);

      .faq-answer p{
        margin: 0 0 10px;
        }
    }

    &.collapsed .faq-answer{
      height: auto;

    }
  }

}


