/* footer container 
--------------------- */
footer {
	background: $white;
	display: block;
    min-height: 20vh;
    overflow: auto;
	position: relative;
	width: $full-width;

    h3 {
        color: $grey-40;
    }

    #nb-inschrijven{

            .frm-field{
                width: 50%;
                padding-right: 5%;
                
                    @include breakpoint(medium) {
                        padding: 0%;
                        margin-bottom: 10px;
                        width: 100%;
                        }
                }
            .frm-submit {
                width: 45%;
                margin: 0;
                
                @include breakpoint(medium) {
                    width: 100%;
                    }
            }

        }


    .footer-content {
        margin: 0 auto;
        max-width: $breakpoint-large;
        width: $full-width;
        }
	
    small.copy {	
		color: $grey-20;
		display: inline-block;
        margin: $content-margin;
		@include font-size(12);
        @include line-height(12);
		}
    .sponsors {
        li {
            margin-right: 25px;
            float: left;

            a{
                display: block;
                height: 100px;
                width: 110px;
                padding: 5px;
                text-align: center;

                img{
                    width: 100%;
                }
                }

        }
    }

    .bx-wrapper .bx-viewport{
        box-shadow: none;
        }

    
    .logo-anbi {
        float: right;
        margin: 20px 0;
    }
    
    .footer-links {
        margin: $content-margin;
        @include line-height(12);
        @include font-size(12);
        text-align: right;
        
        @include breakpoint(medium) {
            text-align: left;
        } 
        ul {
            color: $grey-30;
            display: inline-block;
            
		    li {	
		        border-left: none;
			    float: left;
			    list-style: none;
                margin-bottom: 10px;
                
                @include breakpoint(medium) {
                    float: none;    
                }

            & ~ li {
                border-left: 1px solid $grey-30;
                margin-left: 10px;
                padding-left: 10px;
                
                    @include breakpoint(medium) {
                        border: none;
                        margin-left: 0;
                        padding-left: 0;
                    }
                    
                }
                a {	
                    color: $grey-30;
                }
            }
        }
    }
    #frmEmail {
        @include breakpoint(medium) {
            .column-4 {
                padding: 10px 0;
            }    
        }
    }

}

#layout-wrapper footer .grid .column-4{

    @include breakpoint(medium) {
        width: $full-width;
    }
}