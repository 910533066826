/* layout
--------------------- */
body {
    background-color: $white;
    background-image: url("../gfx/tmpl/bg-image.jpg");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    font-family: $font-opensans;
    @include font-size($font-size-body);
    @include line-height(22);
    
    @include breakpoint(medium) {
        background: $white;
    }
}
	
#layout-wrapper {
	width: $full-width;
}

/* elements
--------------------- */
.fl-left {
	float: left;
	}

.fl-right {
	float: right;
	}