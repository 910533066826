/* grey scales */
$black : #000000;
$grey-10 : #1a1a1a; 
$grey-20 : #333333;
$grey-30 : #4d4d4d; 
$grey-40 : #666666; 
$grey-50 : #808080; 
$grey-60 : #999999; 
$grey-70 : #b3b3b3; 
$grey-80 : #cccccc; 
$grey-90 : #e5e5e5; 
$white : #ffffff;

/* social */
$facebook-blue: #3b5998;
$twitter-blue: #00aced;
$linkedin-blue: #007bb6;
$youtube-red: #bb0000;
$flickr-pink: #00aced;

/* msg */
$msg-succes: #edf8c7;

/* files */
$pdf-red: #ea4c3a;
$word-blue: #2a5696;

/* forms */
$frm-red: #ea4c3a;
$frm-green: #9ebf6a;
$frm-focus: #56B4EF;

/* corporate identity */
$blue: #1b60a3;
$yellow: #eed912; 

$link-idle: $blue;
$link-hover: darken($blue, 10%);
$paragraph: $grey-20;
$list: $grey-20;

/* mobilenav */
$mobilenav-link: $grey-30;
$mobilenav-hover: $grey-40;