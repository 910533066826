/* Tables
--------------------- */
.content table {
	border-collapse: collapse;
    caption-side: bottom;
	margin: $content-margin;
	text-align: left;
    max-width: 100%;
	
    caption {
        color: #999999;
        font-size: 11px;
        margin-bottom: 0.8em;
        margin-top: -0.8em;
	    }
	
	tr {
        th,
        td {
    		border: 1px solid #e8e8e8;
    		padding: 4px;
		}
        th {
            background: #f5f5f5;
            font-weight: bold;
        }
    }
    ul,
    p {
	    margin: 0;
	}
}
	

