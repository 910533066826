
.msg{
  margin-bottom: 5px;
  padding: 7px;
  font-style: italic;

  &.succes{
    background: $msg-succes;
  }
}


.gasteboek-item{
  border-left: 2px solid $grey-90;
  background: $white;
  margin-bottom: 15px;

  .toelichting{
    background: $grey-90;
    padding: 5px 2px;
    margin: 0;
    }

  .bericht{
    font-style: italic;
    margin: 0;
    padding: 10px;
    }

}