/* main navigation
--------------------- */
#mainnav-wrapper {
    display: inline-block;
    height: 60px;
    text-align: right;
    width: 80%;
    
    @include breakpoint(normal) {
        display: none;
    }
}

#mainnav {
    display: inline-block;
    @include font-size(20);
    line-height: 61px;
    color: $grey-20;
    vertical-align: middle;
    
    li {
        margin-left: 10px;
        float: left;
        
        a {
		    display: block;
            text-decoration: none;
            font-size: 1.8rem;
            position: relative;
    	}         
        &:hover a {
        }

        &:hover ul.submenu {
            display: block;
            }

        &.active > a:after {
            background-color: $grey-30;
            bottom: 1px;
            content: "";
            display: inline-block;
            height: 4px;
            left: 0;
            position: absolute;
            width: $full-width;
            }

        /* subnav */
        ul.submenu{
            display: none;
            position: absolute;
            top: 100px;
            z-index: 99;
            width: 200px;
            }

        ul.submenu li{
            background: #fff none repeat scroll 0 0;
            border-bottom: 1px solid $grey-60;
            float: left;
            margin: 0;
            text-align: left;
            width: 100%;

            &:hover{
                background: $grey-90;
                }
            }

            &.active > a:after {
                bottom: 0px;
                }

        ul.submenu li.last{
            border-bottom: none;
            }

        ul.submenu li a{
            min-width: 140px;
            padding: 0 15px;
            }
    }
}




/* Mobile navigation */
.mobilenav-btn { 
    display: none;
        
    @include breakpoint(normal) {
        float: right;
        display: block;
        color: $mobilenav-link;
        display: block;
        height: 30px;
        @include font-size(16);
        margin: 15px 20px;
        padding: 0;
        position: relative;
        text-decoration: none;
        z-index: 9999999;
        
        @include breakpoint(small) {
            margin: 0 20px 20px 20px;
            }
        
        &:hover {
            color: $mobilenav-hover;    
        }
        .mobilenav-icon:before, 
        .mobilenav-icon:after,
        .mobilenav-icon span {
            background: $grey-30;
            content: '';
            display: block;
            height: 4px;
            position: absolute;
            right: 0px;
            transition: none 0.5s ease 0.5s;
            transition-property: transform, top, bottom, right, opacity;
            width: 26px;
        }

        .mobilenav-icon:before {
            top: 5px;
        }
        .mobilenav-icon:after {
            bottom: 5px;
        }
        .mobilenav-icon span {
            top: 50%;
            margin-top: -2px;
        }

        .mobilenav-txt {
            display: block;
            padding: 4px 38px 4px 0px;
            text-transform: uppercase;
        }

        &.mobilenav-opened {
            
            .mobilenav-icon {
                span {
                    right: -50px;
                    opacity: 0;
                }
                &:before {
                    transform: rotate( 45deg );
                    top: 13px;
                }
                &:after {
                    transform: rotate( -45deg );
                    bottom: 13px;
                }
            }
        
        }
        
        #mainnav-mobile #mainnav li {
            float: none;
            width: 100%;
        }

    } //End breakpoint 

}
@include breakpoint(medium) {
    #mainnav-mobile #mainnav {
        padding: 60px 0 0 0;
        width: $full-width;
        
        li, li:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults):after{
            float: none;
            margin: 0;
            width: 100%;
        }

        li.active a:after {
            background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
            bottom: 50%;
            display: block;
            height: 7px;
            left: auto;
            right: 18px;
            width: 7px;

        }

        #mainnav .mm-counter{
            margin-right: 10px;
        }

        a {
            color: $grey-20;
            outline: none;
        }
        &:hover,
        &.active {
            a {
                background: transparent;
            }
        }
    }
}


/*
/* submenu */
#mainnav .mm-subopen {
    position: absolute;
}


