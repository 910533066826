/* Lists
--------------------- */
.content {
    ul,
    ol {
	color: $list;
	list-style: none;
	margin: $content-margin;
	}
    
	ol { /* Ordered list */
		list-style: decimal;
		
		li {
			display: list-item;
            background: none;
			margin-left: 20px;
			padding-left: 0;
			}
    }
    ul { /* Unordered list */ 
        li { 
		padding-left: 15px;
 		position: relative;
	    
            &:before { 
                content: "\f111";
                display: inline;
                font-family: FontAwesome;
                @include font-size(7);
                font-style: normal;
                font-weight: normal;
                left: 1px;
                position: absolute;
                top: 0px;
                -webkit-font-smoothing: antialiased;
  			}
        }
    }
}		