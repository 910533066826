/* only change column-space, rest will auto adjust */
$column-space: 20px;

$grid-columns : 12;
$grid-space : 0 $column-space ($column-space + 5) $column-space;
$content-margin: ($column-space - 5) 0 ($column-space - 5) 0;

.grid {
    display: block;
    overflow: auto;
    padding: $grid-space;
    
    [class*='column-'] {
        display: inline-block;
        float: left;
        padding: 0 $column-space;
        width: 100%;
        min-height: 1px;
    }
  
    @for $i from 1 through $grid-columns {  
        .column-#{$i} {        
            width: 100% / $grid-columns * $i;  
            
            @include breakpoint(small) {
                width: 100%; 
            }            
        }          
    }
    
    &.space-0,
    .space-0 {
        padding: 0;
    }
    
    &.space-l-0,
    .space-l-0 {
        padding-left: 0;
    }
    &.space-r-0,
    .space-r-0 {
        padding-right: 0;
    }
    
    &.space-t-0,
    .space-t-0 {
        padding-top: 0;
    }
    
    &.space-b-0,
    .space-b-0 {
        padding-bottom: 0;
    }
    
    @include breakpoint(medium) {
         &.space-responsive,
        .space-responsive {
            padding: 0 $column-space;
        }
    }
    
}