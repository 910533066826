//http://jaydenseric.com/blog/forget-normalize-or-resets-lay-your-own-css-foundation
html {
    -ms-text-size-adjust: 100%;            
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 62.5%;
}

body {
    margin: 0;
    @include font-size(12);
    @include line-height(19);
}

div, span, h1, h2, h3, h4, p, a, blockquote, figure, ol, ul, li, header, main, footer, article, section, aside, input, select, textarea, fieldset, label {
        -moz-box-sizing:    border-box;
	    -webkit-box-sizing: border-box;
    	-ms-box-sizing: 	border-box;
    box-sizing: 			border-box;
}

h1, h2, h3, h4, p, blockquote, figure, ol, ul {
    margin: 0;
    padding: 0;
}

ul, ol {
    list-style: none;
    }

li {
   display: block;
}

h1, h2, h3, h4 {
    font-size: inherit;
}

a { 
    text-decoration: none;
    color: inherit;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    transition: .3s;
}

img {
    max-width: 100%;
    height: auto;
    border: 0;
}