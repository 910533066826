/* Print
--------------------- */
@media print {
	.content ul {
		list-style-type: circle;
		padding-left: 20px;
		}

	.content li {
		background: none;
		padding-left: 0;
		}
	
	img {
		margin-bottom: 5px;
		max-width: 100%;
		}
	
	#mainnav-wrapper,
	#subnav
    #content-left,
	#content-right,
    footer {
		display: none;
		}
}
