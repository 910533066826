$slider-height : 400px;

.slider-wrapper {
    margin: 0 auto;
    max-width: $full-width;
    width: 100%;  
    position: relative;

    @include breakpoint(medium) {
        padding: 10px 30px 20px 30px;
    }

    .bx-wrapper {
        
        .bx-viewport {
            left: 0;
            border: 0;
            box-shadow: none; 
            height: $slider-height;
            overflow: hidden;

            .bx-slider {
                margin: 0;
                li {
                    height: $slider-height;
                    padding: 0;

                    &:before {
                        content: "";
                        content: none;
                    }
                    img {
                        max-height: none;
                    }
                }
            }
        }

        .bx-caption {
        //        background-image: url("../gfx/css/caption-background.jpg");
        //        background-position: bottom center;
        //        border-bottom-style: solid;
        //        borderbottom-width: 5px;
        //        @include alpha-attribute("border-color", rgba($grey-20, 0.8), $grey-20);
            @include alpha-attribute("background-color", rgba($grey-20, 0.9), $grey-20);
        //        background-color: $grey-20;
            bottom: 20%;
            color: $white;
            @include font-size(20);
            font-weight: 900;
            left: auto;
            right: 0px;
            padding: 14px;
            position: absolute;
            z-index: 100;
            text-transform: uppercase;
            width: auto;   

            @include breakpoint(medium) {
                display: none;
                top: 0;
                bottom: auto;
                @include font-size(14);
                padding: 6px;
            }
            @include breakpoint(small) {
                display: none;
                }

            .caption-wrapper {
                display: block;
                margin: 0 auto;
                padding: 0 10px;
                width: 100%;
            }
        }
    }
}
.bx-pager-wrapper {
    @include alpha-attribute("background-color", rgba($white, 0.1), $white);
    height: 50px;
    right: 0;
    left: 0;
    bottom: 0px;
    position: absolute;
    z-index: 99999999999;
    width: 100%;
    text-align: center;

    @include breakpoint(medium) {
        bottom: 20px;
        }

    @include breakpoint(large) {
         height: 30px;    
     }

    .bx-pager {
        display: inline-block;
        margin: 0 auto;
        padding: 15px 0 0 0;
        width: auto;

        @include breakpoint(large) {
             padding: 4px 0 0 0;
         }

        li {
            float: left;
            margin-left: 6px;
            padding: 0;

            &:before {
                content: "";
                content: none;
            }
            & a {
                color: $white;
                &.active {
                    color: $grey-50;
                }
            }
        }
    }
}