/* Images
--------------------- */
img {
    display: block;
    max-height: 100%;
    max-width: 100%;
    
     &.left {
	    float: left;
	    padding-bottom: 10px;
	    padding-right: 10px;
	    }

    &.right {
	    float: right;
	    padding-bottom: 10px;
	    padding-left: 10px;
	}
}