/**
 * BootstrapValidator (http://bootstrapvalidator.com)
 * The best jQuery plugin to validate form fields. Designed to use with Bootstrap 3
 *
 * @author      http://twitter.com/nghuuphuoc
 * @copyright   (c) 2013 - 2014 Nguyen Huu Phuoc
 * @license     MIT
 */

.bv-form .help-block {
    margin-bottom: 0;
}
.bv-form .tooltip-inner {
    text-align: left;
}
.nav-tabs li.bv-tab-success > a {
    color: #3c763d;
}
.nav-tabs li.bv-tab-error > a {
    color: #a94442;
}
