@function get-googlefont-url($fonts...) {

  $url: "http://fonts.googleapis.com/css?family=";
  $nb: 0;

  @each $font-name in $fonts {
    $nb: $nb + 1;
    $nb-word: 0;

    @each $word in $font-name {
      $nb-word: $nb-word + 1;
      $url: $url + $word;

      @if $nb-word < length($font-name) {
        $url: $url + "+";
      }
    }

    @if $nb < length($fonts) {
      $url: $url + "|";
    }
  }
  @return $url;
}

$font-size-body: 14; //used in layout-main and content-form

$font-opensans : "Open Sans";
$font-opensans-args : ":400,400italic,600,600italic,700,700italic";
$googlefont-url : get-googlefont-url($font-opensans) + $font-opensans-args;

$font-nunito : "Nunito";
$font-nunito-args : ":400,700,300";
$googlefont-url2 : get-googlefont-url($font-nunito) + $font-nunito-args;