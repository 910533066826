

.photo-list{

  }

  .photo-item{
    float: left;
    padding: 5px;
      
    @include breakpoint(medium) {
      padding: 0;
      margin-bottom: 10px;
      width: 100%;
      }
      
    .photo-item a{
      border: 3px solid $grey-80;
      display: block;
      height: 110px;
      overflow: hidden;
      width: 165px;
        
        @include breakpoint(medium) {
            height: auto;
            width: 100%;
            }
        
      &:hover{
        border: 3px solid $grey-50;
        }

      img{
        height: 100%;
        width: 100%;
          
          @include breakpoint(medium) {
            height: auto !important;
            }
        }

    }
    
    .title{
      display: none;
    }
  }